@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  /* Manually set color scheme to dark when our theme is black  */
  .black {
    color-scheme: dark;
  }
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    --primary: 221.2 83.2% 53.3%;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 221.2 83.2% 53.3%;
    --radius: 0.5rem;
    --header-height: 3.5rem;
    --header-bottom-border: 1px;
    --available-height: calc(
      100vh - var(--header-height) - var(--header-bottom-border)
    );
    --available-without-extra-height: calc(var(--available-height) - 6rem);
    --chart-1: 173 58% 39%;
    --chart-2: 12 76% 61%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
    --primary: 217.2 91.2% 59.8%;
    --primary-foreground: 222.2 47.4% 11.2%;
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 224.3 76.3% 48%;
    --chart-1: 220 70% 50%;
    --chart-5: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-2: 340 75% 55%;
  }
  .black {
    --background: 0 0% 0%;
    --foreground: 210 40% 98%;
    --card: 0 0% 0%;
    --card-foreground: 210 40% 98%;
    --popover: 0 0% 0%;
    --popover-foreground: 210 40% 98%;
    --primary: 217.2 91.2% 59.8%;
    --primary-foreground: 222.2 47.4% 11.2%;
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 224.3 76.3% 48%;
    --chart-1: 220 70% 50%;
    --chart-5: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-2: 340 75% 55%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

@layer utilities {
  .popover-content {
    width: var(--radix-popover-trigger-width) !important;
    max-height: var(--radix-popover-content-available-height) !important;
  }
}

.driver-popover.driver-popover-custom {
  @apply bg-card text-card-foreground;
  @apply shadow-lg;
  @apply rounded-lg;
  @apply p-4;
  @apply w-96;
  @apply max-h-96;
}

.driver-popover.driver-popover-custom {
  .driver-popover-navigation-btns button {
    @apply bg-primary font-semibold text-primary-foreground ring-offset-background transition-colors;
    @apply rounded-md;
    @apply items-center justify-center px-4 py-2;
    @apply border-none;
    text-shadow: none;
  }
}

.markdown > * {
  all: revert;
}

.markdown table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1em;
}

.markdown tr {
  border-top: 1px solid #c6cbd1;
  background: #fff;
}

.markdown th,
.markdown td {
  padding: 6px 13px;
  border: 1px solid #dfe2e5;
}

.markdown table tr:nth-child(2n) {
  background: #f6f8fa;
}

.markdown blockquote {
  color: #666;
  margin: 0;
  padding-left: 2em;
  border-left: 0.5em #eee solid;
}
